import React from 'react'
import './error.css'
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
    let navigate = useNavigate();

    return (
        <div>
            <div className="error" style={{ backgroundColor: 'lavender' }}>
                <h1>404 - PAGE NOT FOUND</h1>
                <h3>Sorry, the page you are looking for might have been removed or is temporarily unavailable.</h3>
                <button onClick={() => navigate('/')} style={{ background: 'transparent', border: 'solid white', color: 'white', fontSize: '20px' }}>Click here to go Home</button>
            </div>
        </div>
    )
}

