import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Layout, 
  Card, 
  Typography, 
  Button, 
  Spin, 
  message, 
  Upload,
  Modal
} from 'antd';
import {
  LeftOutlined,
  UploadOutlined,
  DownloadOutlined,
  CloudDownloadOutlined,
  CloseCircleTwoTone,
  CloseOutlined
} from '@ant-design/icons';
import './AttendeePhotos.css';
import AttendeeDropdown from '../components/AttendeeDropdown';
import { CrossIcon } from 'lucide-react';

const { Content } = Layout;
const { Title, Text } = Typography;

const ImageModal = ({ visible, image, onClose }) => {
  const handleDownload = async () => {
    try {
      const response = await fetch(image.url);
      const blob = await response.blob();
      
      const blobUrl = window.URL.createObjectURL(blob);
      
      const link = document.createElement('a');
      link.href = blobUrl;
      
      const fileName = image.key 
        ? image.key.split('/').pop() 
        : `photo-${new Date().getTime()}.jpg`;
      
      link.setAttribute('download', fileName);
      
      document.body.appendChild(link);
      link.click();
      
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Download failed:', error);
      message.error('Failed to download image');
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width="80%"
      className="ap-image-modal"
      closable={true}
      closeIcon={<CloseOutlined className="ap-modal-close-icon" />}
    >
      <div className="ap-modal-content">
        <img src={image?.url} alt="Full size" className="ap-modal-image" />
      </div>
      <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={handleDownload}
          className="ap-download-button"
        >
          Download
        </Button>
    </Modal>
  );
};

const AttendeePhotos = () => {
  const [event, setEvent] = useState(null);
  const [selectedAttendee, setSelectedAttendee] = useState(null);
  const [attendeePhotos, setAttendeePhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const { eventId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchEventDetails();
  }, [eventId]);

  const fetchEventDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/events/${eventId}`);
      if (!response.ok) throw new Error('Failed to fetch event details');
      const data = await response.json();
      setEvent(data);
    } catch (err) {
      setError(err.message);
      message.error('Failed to load event details');
    } finally {
      setLoading(false);
    }
  };

  const fetchAttendeePhotos = async (attendeeName) => {
    if (!attendeeName) return;
    
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/attendee/${eventId}/${attendeeName}/photos`
      );
      if (!response.ok) throw new Error('Failed to fetch attendee photos');
      const data = await response.json();
      
      const processedPhotos = data.photos.map(photo => ({
        ...photo,
        errorCount: 0 
      }));
      
      setAttendeePhotos(processedPhotos);
      setSelectedAttendee(data.attendee);
    } catch (err) {
      setError(err.message);
      message.error('Failed to load photos', 3);
    } finally {
      setLoading(false);
    }
  };

  const handleImageError = (photoKey) => {
    setAttendeePhotos(prevPhotos => 
      prevPhotos.map(photo => {
        if (photo.key === photoKey) {
          const newErrorCount = (photo.errorCount || 0) + 1;
          if (newErrorCount <= 2) {
            return { ...photo, errorCount: newErrorCount };
          }
        }
        return photo;
      })
    );
  };

  const handleFileUpload = async ({ file, onSuccess, onError }) => {
    try {
      setUploading(true);
      
      const formData = new FormData();
      formData.append('file', file);
      formData.append('uploaded_by_name', "Admin");
      formData.append('uploaded_by_phone',  "123456789");

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/upload/${eventId}/${selectedAttendee.name}`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      const data = await response.json();
      message.success('Photo uploaded successfully');
      onSuccess(data);
      
      await fetchAttendeePhotos(selectedAttendee.name);
    } catch (error) {
      console.error('Upload error:', error);
      message.error('Failed to upload photo');
      onError(error);
    } finally {
      setUploading(false);
    }
  };

  const handleImageClick = (photo) => {
    setSelectedImage(photo);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedImage(null);
  };

  const renderPhotoCard = (photo, index) => {
    if (photo.errorCount >= 2) {
      return (
        <div className="photo-card">
          <div className="photo-container error-container">
            <Text type="secondary" className="error-text">
              Image unavailable
            </Text>
          </div>
          <div className="photo-info">
            <Text className="photo-uploader">
              Uploaded by: Unknown
            </Text>
          </div>
        </div>
      );
    }
  
    let uploaderName = 'Unknown';
    const fileIdentifier = photo.key || photo.url || '';
    
    const patterns = [
      /by_([^_]+)_([^_]+)_\d+/,
      /by_([^_]+)/
    ];
  
    for (const pattern of patterns) {
      const match = fileIdentifier.match(pattern);
      if (match && match[1]) {
        uploaderName = match[1].replace(/_/g, ' ')
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
        break;
      }
    }
  
    return (
      <div className="photo-card" onClick={() => handleImageClick(photo)}>
        <div className="photo-container">
          <img
            alt={`Photo ${index + 1}`}
            src={photo.url}
            className="photo-image"
            onError={() => handleImageError(photo.key)}
          />
        </div>
        <div className="photo-info">
          <Text className="photo-uploader">
            Uploaded by: {uploaderName}
          </Text>
          <Text className="photo-timestamp">
            {new Date(photo.timestamp).toLocaleString()}
          </Text>
        </div>
      </div>
    );
  };
  
  return (
    <Layout className="layout-container">
      <Content className="content-wrapper">
        <Card className="main-card">
          <div className="header">
            <div className="header-left">
              <div className='cross-right'>
                <Button
                  icon={<CloseOutlined style={{ fontSize: '32px' }} />}
                  onClick={() => navigate(-1)}
                  style={{ border: 'none', background: 'transparent' }}
                />
              </div>
              <Title level={3} className="page-title">
                {event?.name} - Photos
              </Title>
            </div>
          </div>

          <div className="main-content">
            <div className="attendees-section">
              <Title level={5}>Location / Attendees</Title>
              <AttendeeDropdown
                attendees={event?.attendees || []}
                onSelect={fetchAttendeePhotos}
                selectedAttendee={selectedAttendee}
              />
              <div className="selected-attendee-info">
                {selectedAttendee && (
                  <Text className="selected-text">
                    Currently viewing: {selectedAttendee.name}
                  </Text>
                )}
              </div>
            </div>

            <Card className="photos-section">
              {selectedAttendee ? (
                <>
                  <div className="photos-header">
                    <Title level={4}>Photos of {selectedAttendee.name}</Title>
                    <div className="action-buttons">
                      <Upload
                        customRequest={handleFileUpload}
                        accept="image/*"
                        disabled={uploading}
                        showUploadList={false}
                      >
                        <Button icon={<UploadOutlined />} loading={uploading} style={{ border: 'none', background: 'transparent' }}>
                          {uploading ? 'Uploading...' : 'Upload Photo'}
                        </Button>
                      </Upload>
                    </div>
                  </div>
                  
                  {loading ? (
                    <div className="loading-container">
                      <Spin size="large" />
                    </div>
                  ) : attendeePhotos.length > 0 ? (
                    <div className="photos-grid">
                      {attendeePhotos.map((photo, index) => renderPhotoCard(photo, index))}
                    </div>
                  ) : (
                    <div className="empty-state">
                      <Text>No photos found for this QR code.</Text>
                    </div>
                  )}
                </>
              ) : (
                <div className="empty-state">
                  <Text>Select a QR code to view their photos</Text>
                </div>
              )}
            </Card>
          </div>
        </Card>
      </Content>
      <ImageModal
        visible={modalVisible}
        image={selectedImage}
        onClose={handleModalClose}
      />
    </Layout>
  );
};

export default AttendeePhotos;