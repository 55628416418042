import React, { useState } from 'react';
import { Send } from 'lucide-react';
import './PhoneVerification.css';

const PhoneVerification = ({ onVerificationComplete, phoneNumber, setPhoneNumber }) => {
  const [name, setName] = useState('');
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);

  const handleSendOtp = async (e) => {
    e.preventDefault();
    if (!phoneNumber || !name) {
      setError('Please enter both name and phone number');
      return;
    }

    setLoading(true);
    setError('');
    
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/auth/send-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number: phoneNumber, name }),
      });

      const data = await response.json();
      
      if (response.ok) {
        setSuccess('OTP sent successfully!');
        setShowOtpInput(true);
      } else {
        setError(data.detail || 'Failed to send OTP');
      }
    } catch (err) {
      setError('Failed to send OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (!otp) {
      setError('Please enter the OTP');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/auth/verify-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number: phoneNumber, otp, name }),
      });

      const data = await response.json();
      
      if (response.ok) {
        const userDetails = {
          name,
          phoneNumber,
          verifiedAt: new Date().toISOString()
        };
        localStorage.setItem('verifiedUserDetails', JSON.stringify(userDetails));
        
        setSuccess('Phone number verified successfully!');
        onVerificationComplete?.(name);
      } else {
        setError(data.detail || 'Invalid OTP');
      }
    } catch (err) {
      setError('Failed to verify OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!showOtpInput) {
        await handleSendOtp(e);
      } else {
        await handleVerifyOtp(e);
      }
    }
  };

  const handlePhoneNumberChange = (e) => {
    let value = e.target.value;
    if (value.length === 10 && !value.startsWith('1')) {
      value = '1' + value;
    }
    setPhoneNumber(value);
  };

  return (
    <div className="verification-container">
      <form onSubmit={showOtpInput ? handleVerifyOtp : handleSendOtp} className="verification-form">
        {!showOtpInput && (
          <>
            <div className="form-group">
              <label className="form-label">Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyPress={handleKeyPress}
                className="form-input"
                placeholder="Enter your Full Name"
                required
              />
            </div>
            <div className="form-group">
              <label className="form-label">Phone Number</label>
              <input
                type="tel"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                onKeyPress={handleKeyPress}
                className="form-input"
                placeholder="6141234567"
                required
              />
            </div>
          </>
        )}

        {showOtpInput && (
          <div className="form-group">
            <label className="form-label">Enter OTP</label>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              onKeyPress={handleKeyPress}
              className="form-input"
              placeholder="Enter OTP"
              required
            />
          </div>
        )}

        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}

        <button
          type="submit"
          disabled={loading}
          className={`submit-button ${loading ? 'loading' : ''}`}
        >
          <Send size={20} />
          <span>
            {loading ? 'Processing...' : showOtpInput ? 'Verify OTP' : 'Send OTP'}
          </span>
        </button>
      </form>
    </div>
  );
};

export default PhoneVerification;