// src/pages/CreateEventPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createEvent, addAttendee } from '../api';
import './CreateEventPage.css';
import { ArrowLeft} from 'lucide-react';

function CreateEventPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [eventData, setEventData] = useState({ 
    name: '',
    description: '',
    venue: '',
    date: '',
    time: '',
    type: '',
    phone: ''
  });
  
  const [attendees, setAttendees] = useState([]);
  const [newAttendee, setNewAttendee] = useState({ name: '' });
  const [error, setError] = useState('');
  const [eventId, setEventId] = useState(null);

  const handleAddAttendee = (e) => {
    e.preventDefault();
    if (!newAttendee.name) {
      setError('Please enter attendee name');
      return;
    }
    setAttendees([...attendees, { ...newAttendee, contact: '' }]); // Adding empty contact for API compatibility
    setNewAttendee({ name: '' });
    setError('');
  };

  const handleRemoveAttendee = (index) => {
    setAttendees(attendees.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (attendees.length === 0) {
      setError('Please add at least one attendee');
      return;
    }

    setLoading(true);
    try {
      // First create the event
      const eventResponse = await createEvent(eventData);
      const newEventId = eventResponse.event_id;
      setEventId(newEventId);

      // Then add all attendees
      for (const attendee of attendees) {
        await addAttendee({
          event_id: newEventId,
          ...attendee
        });
      }

      // Navigate to QR codes page
      navigate(`/event/qrcodes/${newEventId}`);
    } catch (error) {
      setError(error.error || 'Failed to create event. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="create-event-container">
      {loading && <div className="loading-overlay">Creating event...</div>}
      <button className="back-button" onClick={() => navigate(-1)}>
      <ArrowLeft size={20} />
        Back
      </button>
      <form onSubmit={handleSubmit} className="event-form">
        <div className="form-section">
          <h2>Event Details</h2>
          <div className="input-group">
            <label>Event Name</label>
            <input
              type="text"
              value={eventData.name}
              onChange={(e) => setEventData({...eventData, name: e.target.value})}
              required
            />
          </div>

          <div className="input-group">
            <label>Description</label>
            <textarea
              value={eventData.description}
              onChange={(e) => setEventData({...eventData, description: e.target.value})}
              required
            />
          </div>

          <div className="input-group">
            <label>Venue</label>
            <input
              type="text"
              value={eventData.venue}
              onChange={(e) => setEventData({...eventData, venue: e.target.value})}
              required
            />
          </div>

          <div className="form-row">
            <div className="input-group">
              <label>Date</label>
              <input
                type="date"
                value={eventData.date}
                onChange={(e) => setEventData({...eventData, date: e.target.value})}
                required
              />
            </div>

            <div className="input-group">
              <label>Time</label>
              <input
                type="time"
                value={eventData.time}
                onChange={(e) => setEventData({...eventData, time: e.target.value})}
                required
              />
            </div>
          </div>

          <div className="input-group">
            <label>Event Type</label>
            <select
              value={eventData.type}
              onChange={(e) => setEventData({...eventData, type: e.target.value})}
              required
            >
              <option value="">Select Type</option>
              <option value="wedding">Wedding</option>
              <option value="birthday">Birthday</option>
              <option value="corporate">Corporate</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div className="input-group">
            <label>Phone Number</label>
            <input
              type="tel"
              value={eventData.phone}
              onChange={(e) => setEventData({...eventData, phone: e.target.value})}
              required
            />
          </div>
        </div>

        <div className="form-section">
          <h2>QR Code Names</h2>
          <div className="attendees-form">
            <div className="rowattendee">
              <input
                type="text"
                placeholder="Add Location Name or Attendee Name"
                value={newAttendee.name}
                onChange={(e) => setNewAttendee({...newAttendee, name: e.target.value})}
              />
              <button type="button" onClick={handleAddAttendee} className="add-button-create">
                Add
              </button>
            </div>
          </div>

          <div className="attendees-list">
            {attendees.map((attendee, index) => (
              <div key={index} className="attendee-item">
                <span>{attendee.name}</span>
                <button 
                  type="button" 
                  onClick={() => handleRemoveAttendee(index)}
                  style={{ padding: '5px 10px', cursor: 'pointer' , maxWidth: '30px'}}                >
                  ×
                </button>
              </div>
            ))}
          </div>
        </div>

        {error && <div className="error-message">{error}</div>}

        <div className="form-buttons">
          <button type="button" onClick={() => navigate('/')} className="cancel-button">
            Cancel
          </button>
          <button type="submit" className="submit-button-create" disabled={loading}>
            Create Event & Generate QR Codes
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateEventPage;