// ScanQRPage.js
import React, { useState, useEffect, useRef } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { ArrowLeft, Camera } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import './ScanQRPage.css';

function ScanQRPage() {
  const [scanResult, setScanResult] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const scannerRef = useRef(null);

  useEffect(() => {
    if (!scannerRef.current) {
      scannerRef.current = new Html5QrcodeScanner('reader', {
        qrbox: {
          width: 300,
          height: 300,
        },
        fps: 5,
        aspectRatio: 1,
      });

      scannerRef.current.render(success, error);
    }

    function success(result) {
      if (scannerRef.current) {
        scannerRef.current.clear();
      }
      setScanResult(result);
      window.location.href = result;
    }

    function error(err) {
      console.warn(err);
      setError('Failed to scan QR code. Please try again.');
    }

    return () => {
      if (scannerRef.current) {
        scannerRef.current.clear();
        scannerRef.current = null;
      }
    };
  }, []);

  return (
    <div className="scan-container">
      <div className="image-background"></div>

      <div className="main-content-upload">
        <div className="card">
          <div className="header-section">
            <div className="decorative-top"></div>
            
            <button className="back-button" onClick={() => navigate(-1)}>
              <ArrowLeft size={20} />
              <span>Back</span>
            </button>
            
            <h1 className="title">
              <span className="name">Scan QR Code</span>
            </h1>
            
            <div className="divider"></div>
            
            <div className="scan-details">
              <span className="anda">Point your camera at the QR code</span>
              <span className="anda">to upload your clicked event's photos</span>
            </div>

          </div>

          <div className="scanner-section">
            <div className="scanner-area">
              <div className="camera-frame">
                <div id="reader"></div>

                {scanResult && (
                  <div className="success-message">
                    <p>QR Code detected! Redirecting...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScanQRPage;