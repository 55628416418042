import React from 'react';
import { useNavigate } from 'react-router-dom';
import { QrcodeOutlined, PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import './HomePage.css';

function HomePage() {
  const navigate = useNavigate();

  return (
    <div className="home-page-container">
      <header className="home-header">
      <img
        src="https://i.ibb.co/G3twYYv/logo-anyqrc-white.png"
        alt="AnyQRC Logo"
        className="home-logo"
      />
      </header>
      <div className="home-main-content">
        <div className="home-card">
          <div className="home-header-section">
            <h2>Welcome to AnyQRC</h2>
            <p>Scan QR codes, create events, and manage attendee's photos with ease.</p>
            <div className="home-divider"></div>
            <h2>Liz & Mike's Wedding</h2>
          </div>
          <div className="home-buttons-section">
            <div className="home-buttons-container">
              <button
                className="home-button home-button-primary"
                onClick={() => navigate('/create_event')}
              >
                <PlusOutlined className="home-button-icon" />
                Create Event
              </button>
              <button
                className="home-button home-button-secondary"
                onClick={() => navigate('/events')}
              >
                <UnorderedListOutlined className="home-button-icon" />
                My Events
              </button>
              <button
                className="home-button home-button-secondary"
                onClick={() => navigate('/scan')}
              >
                <QrcodeOutlined className="home-button-icon" />
                Scan QR Code
              </button>
            </div>
          </div>
        </div>
      </div>
      <p className="footer-heart">
        Made with <span role="img" aria-label="heart">🤍</span>  by Big Kitty Labs 
      </p>
    </div>
  );
}

export default HomePage;