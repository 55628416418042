import React, { useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import CreateEventPage from './pages/CreateEventPage';
import ScanQRPage from './pages/ScanQRPage';
import EventQRCodesPage from './pages/EventQRCodesPage';
import AttendeePhotoPage from './pages/AttendeePhotoPage';
import EventsList from './pages/EventsList';
import AttendeePhotos from './pages/AttendeePhotos';
import NotFound from './notfound/Error';
import PhoneVerification from './components/PhoneVerification';
import 'antd/dist/reset.css';
import './components/VerificationWrapper.css';

const AdminVerificationWrapper = ({ children }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'qrcm@n1a') {
      sessionStorage.setItem('adminVerified', 'true');
      window.location.reload();
    } else {
      setError('Incorrect password');
      setPassword('');
    }
  };

  const isAdminVerified = sessionStorage.getItem('adminVerified') === 'true';

  if (isAdminVerified) {
    return children;
  }

  return (
    <div className="verification-wrapper">
      <div className="verification-card">
        <h1 className="verification-title">Admin Access Required</h1>
        <p className="verification-subtitle">Please enter the admin password to continue</p>
        <form onSubmit={handleSubmit} className="verification-form">
          <div className="form-group">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-input"
              placeholder="Enter password"
              required
            />
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="submit-button">
            Verify
          </button>
        </form>
      </div>
    </div>
  );
};

const ProtectedAdminRoute = ({ element: Component }) => {
  const location = useLocation();
  
  return (
    <AdminVerificationWrapper>
      <Component />
    </AdminVerificationWrapper>
  );
};

const VerificationWrapper = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const location = useLocation();
  const { eventId, attendeeName } = location.state?.params || {};
  
  const handleVerificationComplete = (verifiedName) => {
    const verificationData = {
      timestamp: Date.now(),
      eventId,
      attendeeName,
      verified: true,
      verifiedName: verifiedName // Store the name entered during verification
    };
    sessionStorage.setItem('photoPageVerification', JSON.stringify(verificationData));
    window.location.href = `/event/${eventId}/attendee/${attendeeName}`;
  };

  return (
    <div className="verification-wrapper">
      <div className="verification-card">
        <div className="verification-title">Verify Access</div>
        <div className="divider"></div> 
        <p className="fanda">Please verify your phone number to upload your clicked memories of <strong> Liz & Mike's Wedding</strong></p>
        <PhoneVerification
          onVerificationComplete={handleVerificationComplete}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      </div>
    </div>
  );
};

const ProtectedAttendeeRoute = () => {
  const location = useLocation();
  const { eventId, attendeeName } = location.pathname.match(/\/event\/(?<eventId>[^/]+)\/attendee\/(?<attendeeName>[^/]+)/)?.groups || {};

  const isVerifiedForRoute = () => {
    try {
      const verificationData = JSON.parse(sessionStorage.getItem('photoPageVerification'));
      if (!verificationData) return false;

      const isValid = 
        verificationData.eventId === eventId &&
        verificationData.attendeeName === attendeeName &&
        verificationData.verified &&
        (Date.now() - verificationData.timestamp) < 5 * 60 * 1000;

      return isValid;
    } catch {
      return false;
    }
  };

  if (!isVerifiedForRoute()) {
    return (
      <Navigate 
        to="/verify" 
        state={{ 
          from: location,
          params: { eventId, attendeeName }
        }} 
        replace 
      />
    );
  }

  return <AttendeePhotoPage />;
};

function App() {
  return (
    <div style={{ width: '100vw' }}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/create_event" element={<ProtectedAdminRoute element={CreateEventPage} />} />
        <Route path="/events" element={<ProtectedAdminRoute element={EventsList} />} />
        <Route path="/scan" element={<ScanQRPage />} />
        <Route path="/event/qrcodes/:eventId" element={<EventQRCodesPage />} />
        <Route path="/verify" element={<VerificationWrapper />} />
        <Route path="/event/:eventId/attendee/:attendeeName" element={<ProtectedAttendeeRoute />} />
        <Route path="/event/:eventId/all-photos" element={<AttendeePhotos />} />
      </Routes>
    </div>
  );
}

export default App;