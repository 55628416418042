import React, { useState, useRef, useEffect } from 'react';

const AttendeeDropdown = ({ attendees, onSelect, selectedAttendee }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredAttendees = attendees?.filter(attendee =>
    attendee.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = (attendee) => {
    onSelect(attendee.name);
    setSearchTerm(attendee.name);
    setIsOpen(false);
  };

  return (
    <div className="attendee-dropdown" ref={dropdownRef}>
      <div className="dropdown-header">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setIsOpen(true);
          }}
          onClick={() => setIsOpen(true)}
          placeholder="Search attendees..."
          className="dropdown-input"
        />
        <button 
          className="dropdown-toggle"
          onClick={() => setIsOpen(!isOpen)}
        >
          ▼
        </button>
      </div>
      
      {isOpen && (
        <div className="dropdown-menu">
          {filteredAttendees?.length > 0 ? (
            filteredAttendees.map((attendee) => (
              <div
                key={attendee.id}
                className={`dropdown-item ${
                  selectedAttendee?.name === attendee.name ? 'selected' : ''
                }`}
                onClick={() => handleSelect(attendee)}
              >
                {attendee.name}
              </div>
            ))
          ) : (
            <div className="dropdown-item no-results">
              No attendees found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AttendeeDropdown;